import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS_V2, FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        debouncedGetLocations: null,
        isLocationsLoading: false,
        searchLocations: null,
        searchLocationsPerSite: null,
        locationsPaginationPage: 1
    }),
    computed: {
        ...mapState('locations', [
            'totalLocationsAmount'
        ]),
        ...mapGetters('locations', [
            'locations'
        ])
    },
    watch: {
        searchLocations: {
            handler (val, oldVal) {
                if (!val || this.isLocationsLoading || val === oldVal) {
                    return;
                }

                this.debouncedGetLocations(val);
            }
        },
        searchLocationsPerSite: {
            handler (val, oldVal) {
                if (!val || this.isLocationsLoading || val === oldVal) {
                    return;
                }

                let siteId = null;

                if (this.site_id) {
                    siteId = this.site_id;
                }

                if (this.filters?.[FILTERS_V2.LOCATION]) {
                    siteId = this.filters[FILTERS_V2.LOCATION].join(',');
                }

                this.debouncedGetLocations(val, null, siteId);
            }
        }
    },
    methods: {
        getLocations (val, searchID = null, siteId = null) {
            this.isLocationsLoading = true;

            const params = {};

            if (searchID) {
                params['filter[id]'] = searchID;
            } else {
                params['filter[name][match]'] = val;
            }

            if (siteId) {
                const data = Array.isArray(siteId) ? siteId.join(',') : siteId;
                params['filter[place_id]'] = data;
            }

            return this.$store.dispatch('locations/getLocations', params)
                .finally(() => {
                    this.isLocationsLoading = false;
                });
        },
        getLocationsLazyLoading (siteId = null) {
            if (this.totalLocationsAmount > this.locations.length) {
                this.isLocationsLoading = true;

                this.locationsPaginationPage += 1;

                const params = {
                    'page[number]': this.locationsPaginationPage
                };

                if (siteId || this.filters?.[FILTERS_V2.LOCATION] || this.modalFilters?.[FILTERS_V2.LOCATION]) {
                    let data = siteId || this.filters?.[FILTERS_V2.LOCATION] || this.modalFilters?.[FILTERS_V2.LOCATION];
                    data = Array.isArray(data) ? data.join(',') : data;
                    params['filter[place_id]'] = data;
                }

                const _name = this.searchLocations ||
                    this.searchLocationsPerSite ||
                    this.filtersSearching?.[FILTERS_SEARCHING.LOCATION] ||
                    this.filtersSearching?.[FILTERS_SEARCHING.LOCATION_PER_SITE] ||
                    this.modalFiltersSearching?.[FILTERS_SEARCHING.LOCATION] ||
                    this.modalFiltersSearching?.[FILTERS_SEARCHING.LOCATION_PER_SITE];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                return this.$store.dispatch('locations/getLocationsLazyLoading', params)
                    .finally(() => {
                        this.isLocationsLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetLocations = debounce(this.getLocations, 1000);
    }
};
