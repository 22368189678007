class FilterInstance {
    constructor (params) {
        this.type = params.type;
        this.model = params.model;
        this.label = params.label || '';
    }

    hasProperty (name) {
        return Object.prototype.hasOwnProperty.call(this, name);
    }
}

export default FilterInstance;
