<template>
    <Modal :modal-title="modalTitle"
           :dialog="dialog"
           max-width="1600px"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <v-row>
                <v-col cols="12">
                    <h3 class="font-weight-medium mb-3">{{ $t('labels.details') }}</h3>
                    <v-row>
                        <v-col md="12" sm="12" cols="12">
                            <v-sheet color="white" class="pa-4" elevation="1">
                                <v-layout align-stretch :wrap="isMobile">
                                    <v-flex sm6
                                            :class="{'bordered pr-4': smAndUp}">
                                        <InformationItem
                                            :title="$t('labels.name')"
                                            name="name"
                                            :text="trackData.name"
                                        ></InformationItem>
                                        <InformationItem
                                            :title="$t('labels.point')"
                                            name="point"
                                            :text="trackData.point.name"
                                        ></InformationItem>
                                        <InformationItem
                                            :title="$t('labels.assignedEmployee')"
                                            name="assignedEmployee"
                                            :text="trackData.assignedEmployee.name"
                                        ></InformationItem>
                                    </v-flex>
                                    <v-flex sm6 :class="{'bordered pl-4': smAndUp}">
                                        <InformationItem
                                            :title="$t('labels.trackType')"
                                            name="type"
                                            :text="this.$t(TRACK_TYPE_TRANSLATE_KEYS[trackData.sequence.type])"
                                        ></InformationItem>
                                        <InformationItem
                                            :title="$t('labels.incidentType')"
                                            name="incidentType"
                                            :text="trackData.incidentType.name"
                                        ></InformationItem>
                                        <InformationItem
                                            v-if="trackData.inspectionTemplate && trackData.inspectionTemplate.name"
                                            :title="$t('labels.inspectionTemplate')"
                                            name="inspectionTemplate"
                                            :text="trackData.inspectionTemplate.name"
                                        ></InformationItem>
                                        <InformationItem
                                            :title="$t('labels.total_duration')"
                                            name="durationTime"
                                            :text="timeToString(trackData.sequence.durationTime)"
                                        ></InformationItem>
                                    </v-flex>
                                </v-layout>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <h3 class="font-weight-medium mb-3">{{ $t('labels.steps') }}</h3>
                    <v-sheet color="white" elevation="1">
                        <v-data-table
                            :items="trackData.sequence.activeSteps"
                            :headers="headers"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:item.order="{ item }">
                                <span>{{ item.order + 1 }}</span>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <span>{{item.point && item.point.name || item.employee && item.employee.name || '-'}}</span>
                            </template>
                            <template v-slot:item.type="{ item }">
                                <span>{{item.verificationWay}}{{item.template && item.template.name ? ` - ${item.template.name}` : ''}}</span>
                            </template>
                            <template v-slot:item.timeSinceSequenceStart="{ item }">
                                <span>{{timeToString(item.timeSinceSequenceStart)}}</span>
                            </template>
                            <template v-slot:item.tolerance="{ item }">
                                <span>{{timeToString(item.tolerance)}}</span>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <h3 class="font-weight-medium mb-3">{{ $t('tracks.repeating_title') }}</h3>
                            <v-sheet color="white" class="pa-4" elevation="1">
                                <InformationItem
                                    :title="$t('labels.occurance_time')"
                                    name="startTime"
                                    :text="trackData.startTime"
                                />
                                <InformationItem
                                    :title="$t('labels.end_time')"
                                    name="endTime"
                                    :text="trackData.endTime"
                                />
                                <InformationItem
                                    :title="$t('labels.number_of_repetitions')"
                                    name="repetitionCount"
                                    :text="trackData.sequence.repetitionCount"
                                />
                                <InformationItem
                                    :title="$t('labels.track_step_config')"
                                    name="repetitionPattern"
                                    :text="$t(REPETITION_PATTERN_TRANSLATE_KEYS[trackData.sequence.repetitionPattern])"
                                />
                                <InformationItem
                                    :title="$t('labels.track_remind_time')"
                                    name="timeToNotifyBeforeSequence"
                                    :text="timeToString(trackData.timeToNotifyBeforeSequence)"
                                />
                                <InformationItem
                                    :title="$t('labels.track_notify_by_email')"
                                    name="notifyByEmail"
                                    :text="trackData.notifyByEmail ? this.$t('answers.yes') : this.$t('answers.no')"
                                />
                            </v-sheet>
                        </v-col>
                        <v-col cols="6">
                            <h3 class="font-weight-medium mb-3">{{ $t('tracks.block_config_title') }}</h3>
                            <v-sheet color="white" class="pa-4" elevation="1">
                                <InformationItem
                                    :title="$t('labels.start_date')"
                                    name="startDate"
                                    :text="trackData.startDate"
                                />
                                <InformationItem
                                    :title="$t('labels.end_date')"
                                    name="endDate"
                                    :text="trackData.endDate"
                                />
                                <InformationItem
                                    :title="$t('labels.repetitionScheme')"
                                    name="repetitionScheme"
                                    :text="$t(REPETITION_CYCLE_TRANSLATE_KEYS[trackData.repetitionScheme])"
                                />
                                <InformationItem
                                    v-if="trackData.cycleFrequencyInDays"
                                    :title="$t('tracks.block_repeats_days_label')"
                                    name="cycleFrequencyInDays"
                                    :text="trackData.cycleFrequencyInDays"
                                />
                                <InformationItem
                                    v-if="trackData.repetitionWeekdays && trackData.repetitionWeekdays.length > 0"
                                    :title="$t('labels.track_repetition_weekdays')"
                                    name="repetitionWeekdays"
                                    :text="weekdaysToString(trackData.repetitionWeekdays)"
                                />
                                <InformationItem
                                    v-if="trackData.repetitionDates && trackData.repetitionDates.length > 0"
                                    :title="$t('labels.track_repetition_dates')"
                                    name="repetitionDates"
                                    :text="trackData.repetitionDates.join(', ')"
                                />
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal.vue';
import { mapState } from 'vuex';
import InformationItem from '@/components/customers/InformationItem.vue';
import {
    REPETITION_CYCLE_TRANSLATE_KEYS,
    REPETITION_PATTERN_TRANSLATE_KEYS, TRACK_TYPE_ENUM,
    TRACK_TYPE_TRANSLATE_KEYS, WEEKDAYS_TRANSLATE_KEYS_ARRAY
} from '@/constants/cyclicRevision';

export default {
    name: 'TrackDetails',
    components: {
        InformationItem,
        Modal
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        REPETITION_CYCLE_TRANSLATE_KEYS () {
            return REPETITION_CYCLE_TRANSLATE_KEYS;
        },
        REPETITION_PATTERN_TRANSLATE_KEYS () {
            return REPETITION_PATTERN_TRANSLATE_KEYS;
        },
        TRACK_TYPE_TRANSLATE_KEYS () {
            return TRACK_TYPE_TRANSLATE_KEYS;
        },
        ...mapState('manageTrack', [
            'trackData'
        ]),
        modalTitle () {
            return this.$t('labels.track') + ' ' + this.trackData.name;
        },
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        headers () {
            let headerList = [
                {
                    text: this.$t('tracks.table.lp'),
                    value: 'order',
                    sortable: false
                },
                {
                    text: this.$t('tracks.table.title'),
                    value: 'name',
                    sortable: false
                },
                {
                    text: this.$t('tracks.table.id_type'),
                    value: 'type',
                    sortable: false
                }
            ];

            if (this.trackData.sequence.type === TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME) {
                headerList = headerList.concat([
                    {
                        text: this.$t('tracks.table.time'),
                        value: 'timeSinceSequenceStart',
                        sortable: false
                    },
                    {
                        text: this.$t('tracks.table.tolerance'),
                        value: 'tolerance',
                        sortable: false
                    }
                ]);
            }

            return headerList;
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        weekdaysToString (weekdays) {
            return weekdays.map(day => this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[day])).join(', ');
        },
        timeToString (time) {
            return time ? time.HH + ':' + time.mm : '';
        }
    }
};
</script>

<style scoped lang="scss">
</style>
