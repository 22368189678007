<template>
    <Modal :dialog="dialog"
           max-width="400px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="confirm(true)"
           @keydown.esc="confirm(false)"
           :with-close-button="false"
           @visibility="visibility($event)">
        <template slot="content">

            <p class="mb-0 text-center text--m">{{ text }}</p>
            <span data-test="testKeydownSpan"></span>
            <v-layout align-center justify-center class="py-5">
                <v-btn color="secondary"
                       v-if="!hideFalseButton"
                       outlined
                       data-test="falseBtn"
                       class="mr-3"
                       @click="confirm(false)">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="trueBtn"
                       :loading="actionLoader"
                       :disabled="actionLoader"
                       @click="confirm(true)">{{ buttonTitle }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';

export default {
    name: 'ConfirmationDialog',
    components: { Modal },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            'default': ''
        },
        actionButtonTitle: {
            type: String,
            'default': ''
        },
        actionLoader: {
            type: Boolean,
            'default': false
        },
        hideFalseButton: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        buttonTitle () {
            return this.actionButtonTitle || this.$t('buttons.ok');
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        confirm (val) {
            this.$emit('confirm', val);
            this.close();
        }
    }
};
</script>

<style scoped>

</style>
