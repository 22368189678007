<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-menu
        v-model="menu"
        ref="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="year"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
            <ValidationProvider :name="label"
                                :vid="name"
                                :rules="rules"
                                v-slot="{ errors }">
                <v-text-field
                    v-bind="$attrs"
                    v-model="year"
                    readonly
                    outlined
                    autocomplete="off"
                    :error-messages="errors"
                    :class="className"
                    :append-icon="ICONS.CALENDAR"
                    v-on="on">
                    <template slot="label">
                        {{ label }}
                        <span v-if="isRequired" class="error--text">*</span>
                    </template>
                </v-text-field>
            </ValidationProvider>
        </template>
        <v-date-picker
            ref="picker"
            v-model="year"
            v-bind="$attrs"
            v-on="$listeners"
            :locale="currentLocale"
            @click:year="saveYear($event)"></v-date-picker>
    </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';
import { ICONS } from '@/constants/icons';

export default {
    name: 'YearPicker',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        label: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        className: {
            type: [String, Array, Object],
            required: false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        }
    },
    data: () => ({
        menu: false,
        year: null,
        ICONS
    }),
    computed: {
        ...mapState('locale', [
            'currentLocale'
        ])
    },
    watch: {
        '$attrs.value': {
            handler (val) {
                this.year = val;
            },
            immediate: true
        },
        menu (val) {
            val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'));
        }
    },
    methods: {
        saveYear (year) {
            this.$refs.menu.save(`${year}`);
            this.$refs.picker.activePicker = 'YEAR';
            this.menu = false;
            this.$emit('input', `${year}`);
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-text-field {
            & .v-input__prepend-outer {
                margin-top: 9px;
            }
        }

        & .v-date-picker-title__date {
            display: none;
        }

        & .v-date-picker-title__year {
            margin-bottom: 0;
        }
    }
</style>
