<template>
    <span class="d-inline-block">
        <span @click="confirmOperation()">
            <slot>
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.DELETE"
                             :tooltip="$t('actions.delete')"></HintingIcon>
            </slot>
        </span>
        <ConfirmationDialog :dialog="showConfirmation"
                            :text="text"
                            :action-button-title="buttonTitle"
                            :action-loader="loader"
                            @confirm="executeAction($event)"></ConfirmationDialog>
    </span>
</template>

<script>
import HintingIcon from '@/components/widgets/HintingIcon';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import { ICONS } from '@/constants/icons';

export default {
    name: 'ButtonWithConfirmation',
    components: { HintingIcon, ConfirmationDialog },
    props: {
        itemId: {
            type: [Number, String, Array]
        },
        callback: {
            type: Function,
            required: true
        },
        actionButtonTitle: {
            type: String,
            'default': ''
        },
        confirmationText: {
            type: String,
            'default': ''
        }
    },
    data: () => ({
        ICONS,
        showConfirmation: false,
        loader: false
    }),
    computed: {
        buttonTitle () {
            return this.actionButtonTitle || this.$t('buttons.delete');
        },
        text () {
            return this.confirmationText || this.$t('messages.delete_confirmation');
        }
    },
    methods: {
        confirmOperation () {
            this.showConfirmation = true;
        },
        action () {
            return (this.itemId || this.itemId === 0) ? this.callback(this.itemId) : this.callback();
        },
        executeAction (event) {
            if (event) {
                this.loader = true;

                this.action()
                    .then(() => {
                        this.$emit('action-executed');
                        this.showConfirmation = false;
                    })
                    .finally(() => {
                        this.loader = false;
                    });
            } else {
                this.showConfirmation = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
