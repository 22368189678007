
export default {
    methods: {
        setupPagination () {
            const amount = this.$store.state.systemSettings.settings?.find(e => e.setting_type === 'NUMBER_PER_PAGE');
            return {
                page: 1,
                amount: parseInt(amount?.value) || 20,
                amountAll: null
            };
        }
    }
};
