<template>
    <div class="footer-table">
        <v-container v-if="$vuetify.breakpoint.smAndDown">
            <v-row>
                <v-col cols="4"
                        @click="prevPage">
                    <v-icon medium>{{ ICONS.SINGLE_LEFT }}</v-icon>
                </v-col>

                <v-col cols="4"
                        class="text-center">
                    <p class="pagination-text"><span class="fw-600">{{ pagination.page }}</span> / {{ lastPage }}</p>
                </v-col>

                <v-col @click="nextPage" class="text-right">
                    <v-icon medium color="primary">{{ ICONS.SINGLE_RIGHT }}</v-icon>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="mb-0 text-right text--xs">
                    {{$t('labels.all_records')}} : {{ pagination.amountAll }}
                </v-col>
            </v-row>
        </v-container>
        <v-row align-content-center
                  class="mt-3"
                  align="center"
                  fill-height
                  v-else>
            <v-col lg="4" md="3">
                <p class="mb-0 text-left text--xs">
                      {{$t('labels.all_records')}} : {{ pagination.amountAll }}
                </p>
            </v-col>
            <v-col lg="4" md="5">
                <div class="text-center mb-0">
                    <div class="wrapper">
                        <a @click.stop="setPage(1)"
                           class="mr-2"
                           data-test="firstPageBtn"
                           :disabled="pagination.page === 1">
                            <v-icon size="20">{{ ICONS.DOUBLE_LEFT }}</v-icon>
                        </a>
                        <a @click.stop="prevPage"
                           class="mr-2"
                           data-test="prevPageBtn"
                            :disabled="pagination.page === 1">
                            <v-icon size="20">{{ ICONS.SINGLE_LEFT }}</v-icon>
                        </a>
                        <div v-if="showFirstPage" class="d-inline-flex">
                            <a @click.stop="setPage(1)"
                               class="mr-2"
                               :class="{'active': pagination.page === 1}">1</a><span v-if="hideRangeStart" class="pr-2">...</span>
                        </div>
                        <a v-for="n in getPageList" :key="n"
                           class="mr-2"
                           :data-test="`page_${n}`"
                           @click.stop="setPage(n)"
                           :class="{'active': n === pagination.page}">{{ n }} </a>
                        <div  class="d-inline-flex" v-if="showLastPage"><span v-if="hideRangeEnd" class="pr-2">...</span>
                            <a @click.stop="setPage(lastPage)"
                               class="mr-2"
                               data-test="page_lastPage"
                               :class="{'active': lastPage === pagination.page}">{{ lastPage }}</a>
                        </div>
                        <a @click.stop="nextPage"
                           class="mr-2"
                           data-test="nextPageBtn"
                           :disabled="pagination.page === lastPage">
                            <v-icon size="20">{{ ICONS.SINGLE_RIGHT }}</v-icon>
                        </a>
                        <a @click.stop="setPage(lastPage)"
                           data-test="lastPageBtn"
                           :disabled="pagination.page === lastPage">
                            <v-icon size="20">{{ ICONS.DOUBLE_RIGHT }}</v-icon>
                        </a>
                    </div>
                </div>
            </v-col>
            <v-col lg="4">
                <p class="mb-0 text-right">
<!--                    NOTE:    UKRYTY MECHANIZM UTAWIANIA PAGINACJI            -->
<!--                    <span class="wrapper">-->
<!--                        <span class="d-inline-block mr-2 text&#45;&#45;xs">{{ $t('labels.items_per_page') | colons }} </span>-->
<!--                        <a v-for="(item, index) in perPage"-->
<!--                           :key="index"-->
<!--                           :data-test="`amount_${item.amount}`"-->
<!--                           @click.stop="setItemsPerPage(item.amount)"-->
<!--                           :class="{'active': item.amount === pagination.amount, 'mr-2': index < perPage.length -1}">-->
<!--                            {{ item.amount }}-->
<!--                        </a>-->
<!--                    </span>-->
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import filters from '@/constants/filters';
import { ICONS } from '@/constants/icons';

export default {
    name: 'Pagination',
    props: {
        pagination: {},
        perPage: {
            type: Array,
            'default': () => [
                { amount: 20 },
                { amount: 50 },
                { amount: 100 }
            ]
        }
    },
    filters: { ...filters },
    data: () => ({
        ICONS
    }),
    computed: {
        lastPage () {
            return Math.ceil(this.pagination.amountAll / this.pagination.amount);
        },
        getPageList () {
            const startLimit = (this.pagination.page === this.lastPage) ? 3 : 2;
            const start = Math.max(0, this.pagination.page - startLimit);
            const end = Math.min(start + 3, this.lastPage);
            const pages = [];
            for (let i = 1; i <= this.lastPage; i++) {
                pages.push(i);
            }
            return pages.slice(start, end);
        },
        showLastPage () {
            return this.lastPage > 3 && this.pagination.page + 1 < this.lastPage;
        },
        showFirstPage () {
            return this.pagination.page >= 3;
        },
        hideRangeStart () {
            return this.pagination.page > 3;
        },
        hideRangeEnd () {
            return this.lastPage !== 4 && this.pagination.page + 2 !== this.lastPage;
        }
    },
    methods: {
        setPage (value) {
            this.$emit('change-page', value);
        },
        setItemsPerPage (value) {
            this.$emit('change-amount', value);
        },
        nextPage () {
            if (this.pagination.page < this.lastPage) {
                this.setPage(this.pagination.page + 1);
            }
        },
        prevPage () {
            if (this.pagination.page > 1) {
                this.setPage(this.pagination.page - 1);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "@/assets/css/_variables";

    .footer-table {
        min-height: 50px;
    }

    .wrapper {
        display: inline-flex;
        align-items: center;
        & a {
            width: 28px;
            height: 28px;
            font-size: $xs;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $light_grey_2;
            border-radius: 4px;
            &.active {
                background: $secondary;
                color: #fff;
                border-color: $secondary;
            }
            &:hover {
                background: $secondary;
                color: #fff;
                border-color: $secondary;
                & * {
                    color: #fff;
                }
                & .v-icon {
                    color: #fff;
                }
            }
            &[disabled="disabled"] {
                background: $tertiary;
                border-color: $tertiary;
                cursor: default;
                & * {
                    color: $text;
                    opacity: 0.5;
                }
            }
        }
    }
</style>
