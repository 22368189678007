<template>
    <component :is="tag" v-intersect="onIntersect"
         :data-last="isLast">
        <slot :item="item"></slot>
    </component>
</template>

<script>
export default {
    name: 'InfiniteScroll',
    props: {
        itemsLength: {
            type: [Number, String],
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        index: {
            type: [Number, String],
            required: true
        },
        tag: {
            type: String,
            'default': 'div'
        }
    },
    computed: {
        isLast () {
            return this.index === this.itemsLength - 1;
        }
    },
    methods: {
        onIntersect (entries, observer) {
            if (entries[0].isIntersecting && entries[0].target.dataset.last) {
                this.$emit('load-more-items');
            }
        }
    }
};
</script>

<style scoped>

</style>
