import FilterInstance from '@/models/filters/filterInstance';

class AutocompleteFilter extends FilterInstance {
    constructor (params) {
        super({
            type: 'Autocomplete',
            model: params.model,
            label: params.label
        });

        this.loading = params.loading || false;
        this.multiple = params.multiple || false;
        this.disabled = params.disabled || false;
        this.successMessages = params.successMessages || '';

        if (Object.hasOwnProperty.call(params, 'items')) {
            if (Array.isArray(params.items)) {
                this.items = params.items;
            } else {
                this.items = {
                    obj: params.items.obj,
                    module: params.items.module,
                    prop: params.items.prop
                };
            }
        } else {
            this.items = [];
        }

        if (Object.hasOwnProperty.call(params, 'search')) {
            this.search = params.search;
        }
        if (Object.hasOwnProperty.call(params, 'lazy')) {
            this.lazy = params.lazy;
        }
        if (Object.hasOwnProperty.call(params, 'searchCallback')) {
            this.searchCallback = params.searchCallback;
        }
        if (Object.hasOwnProperty.call(params, 'referralCallback')) {
            this.referralCallback = params.referralCallback;
        }
        if (Object.hasOwnProperty.call(params, 'searchOptions')) {
            this.searchOptions = {
                searchId: params.searchOptions.searchId || null,
                reference: params.searchOptions.reference
            };
        }
    }
}

export default AutocompleteFilter;
