var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-8",class:{'nowrap': _vm.$vuetify.breakpoint.mdAndUp, 'px-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"align-center":"","justify-space-between":""}},[_c('v-col',{staticClass:"align-self-end"},[_c('a',{staticClass:"mr-3",on:{"click":_vm.emitFetch}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.REFRESH))]),_vm._v(" "+_vm._s(_vm.$t('buttons.refresh_list'))+" ")],1)]),_c('v-col',[_c('v-layout',{attrs:{"justify-end":""}},[(_vm.canAddTrack)?_c('v-btn',{attrs:{"color":"secondary","data-test":"addNewEmployeeBtn"},on:{"click":function($event){return _vm.openTrackConfiguration()}}},[_vm._v(_vm._s(_vm.$t('tracks.add_title'))+" ")]):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-header":_vm.$vuetify.breakpoint.smAndUp,"disable-sort":"","loading":_vm.loader,"items":_vm.tracks,"disable-pagination":"","hide-default-footer":"","show-select":""},on:{"dblclick:row":_vm.showTrackDetails},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(headerItem,index){return _c('th',{key:index},[(headerItem.value === 'data-table-select')?_c('v-simple-checkbox',{attrs:{"ripple":false,"indeterminate":props.someItems && !props.everyItem,"color":"purple"},on:{"input":on['toggle-select-all']},model:{value:(props.everyItem),callback:function ($$v) {_vm.$set(props, "everyItem", $$v)},expression:"props.everyItem"}}):_c('SortableHeader',{attrs:{"header-item":headerItem},on:{"sort":_vm.sort}})],1)}),0)])]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.showDetailsDialog(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.DETAILS,"data-test":"showDetailsBtn","tooltip":_vm.$t('actions.view')},on:{"click":function($event){return _vm.showDetailsDialog(item)}}}),(_vm.canEditTrack)?_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.EDIT,"data-test":"editTrackBtn","tooltip":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.openTrackConfiguration(item)}}}):_vm._e(),(_vm.canDeleteTrack)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteTrack}}):_vm._e()],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.showGroupActions)?_c('div',{staticClass:"mt-6"},[(_vm.canDeleteTrack)?_c('ButtonWithConfirmation',{attrs:{"item-id":_vm.selected,"callback":_vm.deleteManyTracks}},[[_c('a',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.DELETE))]),_vm._v(" "+_vm._s(_vm.$t('buttons.remove_selected'))+" ")],1)]],2):_vm._e()],1):_vm._e(),(_vm.tracksTotalAmount > _vm.pagination.amount)?_c('Pagination',{attrs:{"pagination":_vm.pagination},on:{"change-page":function($event){return _vm.changePage($event)},"change-amount":function($event){return _vm.changeAmount($event)}}}):_vm._e(),(_vm.activeTrack)?_c('TrackDetails',{attrs:{"dialog":_vm.showDetails},on:{"visibility":function($event){_vm.showDetails = $event},"close":_vm.closeDetailsDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }