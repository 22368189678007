<template>
    <v-layout class="mb-2">
        <v-flex v-bind="titleSize">{{ title | colons }}</v-flex>
        <v-flex v-bind="textSize"
                class="pl-2">
            <span @dblclick="editField"
                  :class="{'clickable': clickable}">
                <slot>{{ text }}</slot>
            </span>
        </v-flex>
    </v-layout>
</template>

<script>
import filters from '@/constants/filters';

export default {
    name: 'InformationItem',
    props: {
        title: {
            type: String,
            required: true
        },
        text: {
            type: [String, Number],
            'default': ''
        },
        name: {
            type: String,
            required: true
        },
        clickable: {
            type: Boolean,
            'default': true
        },
        titleSize: {
            type: Object,
            'default': () => ({
                md4: true,
                sm5: true,
                xs5: true
            })
        },
        textSize: {
            type: Object,
            'default': () => ({
                md8: true,
                sm7: true,
                xs7: true
            })
        }
    },
    filters: { ...filters },
    methods: {
        editField () {
            this.$store.commit('customers/SET_FOCUSED_FIELD', this.name);
            this.$emit('edit');
        }
    }
};
</script>

<style scoped>
</style>
