<template>
    <ValidationProvider :name="name">
        <v-checkbox v-on="$listeners"
                    v-model="innerValue"
                    :value="inputValue"
                    :class="className"
                    @change="emitChange"
                    @click="emitClick"
                    v-bind="$attrs">
            <template v-if="slotName"
                      :slot="slotName">
                <slot></slot>
            </template>
        </v-checkbox>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'Checkbox',
    components: { ValidationProvider },
    props: {
        inputValue: {
            required: false
        },
        value: {
            type: null
        },
        slotName: {
            type: String,
            required: false
        },
        name: {
            type: String,
            'default': ''
        },
        className: {
            type: [String, Array, Object],
            'default': ''
        }
    },
    data: () => ({
        innerValue: ''
    }),
    watch: {
        innerValue (newVal) {
            this.$emit('input', newVal);
        },
        value: {
            handler (newVal) {
                this.innerValue = newVal;
            },
            immediate: true
        }
    },
    methods: {
        emitChange () {
            this.$emit('change');
        },
        emitClick () {
            this.$emit('click');
        }
    }
};
</script>

<style scoped>

</style>
