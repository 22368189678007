<template>
    <div>
        <TextField v-model.trim="search"
                   data-test="search"
                   @keyup.enter="handleSearch"
                   :label="$t('labels.search')"
                    slotName="append">
            <template slot="slotContent">
                <v-icon color="medium_text">{{ ICONS.SEARCH }}</v-icon>
            </template>
        </TextField>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';

export default {
    name: 'Search',
    components: { TextField },
    data: () => ({
        ICONS
    }),
    computed: {
        ...mapFields('search', [
            'search'
        ])
    },
    methods: {
        handleSearch () {
            this.$emit('search', this.search);
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-text-field--enclosed .v-input__append-inner {
            margin-top: 0;
            align-self: center;
        }
        & .v-text-field {
           & input::placeholder{
               font-size: 0.8rem;
           }
        }
    }
</style>
