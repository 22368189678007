<template>
    <v-layout align-center
              justify-start>
        <v-icon color="rgba(0, 0, 0, 0.6)"
                v-if="headerItem.icon"
                size="16">{{ headerItem.icon }}</v-icon>
        <span v-else>{{ headerItem.text }}</span>
        <v-icon color="rgba(0, 0, 0, 0.6)"
                v-if="headerItem.sortable"
                class="ml-2"
                :data-test="`sort_${headerItem.value}`"
                @click="sort()"
                size="16">{{ ICONS.SORT }}</v-icon>
    </v-layout>
</template>

<script>
import { ICONS } from '@/constants/icons';

export default {
    name: 'SortableHeader',
    props: {
        headerItem: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        ICONS
    }),
    methods: {
        sort () {
            this.$emit('sort', this.headerItem.value);
        }
    }
};
</script>

<style scoped>

</style>
