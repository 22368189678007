<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-menu
        v-model="menu"
        ref="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
            <ValidationProvider :name="label"
                                :vid="name"
                                :rules="rules"
                                v-slot="{ errors }">
                <v-text-field
                    v-bind="$attrs"
                    :value="format ? dateFormatted: date"
                    outlined
                    readonly
                    autocomplete="off"
                    :error-messages="errors"
                    :class="className"
                    v-on="on">
                    <template slot="label">
                        {{ label }}
                        <span v-if="isRequired" class="error--text">*</span>
                    </template>
                    <template slot="append">
                        <v-icon size="18"
                                class="clickable mr-1"
                                @click="clearDate"
                                v-if="showClearButton">{{ ICONS.CLOSE }}
                        </v-icon>
                        <v-icon>{{ ICONS.CALENDAR }}</v-icon>
                    </template>
                </v-text-field>
            </ValidationProvider>
        </template>
        <v-date-picker
            v-bind="$attrs"
            v-on="$listeners"
            :locale="currentLocale"
            :class="{'v-picker--disabled': onlyDate}"
            @input="menu = false"></v-date-picker>
    </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';
import { ICONS } from '@/constants/icons';
import { PARSE_DATE } from '@/helpers/dates';

export default {
    name: 'DatePicker',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        label: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        className: {
            type: [String, Array, Object],
            required: false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        clearable: {
            type: Boolean,
            'default': false
        },
        onlyDate: {
            type: Boolean,
            'default': false
        },
        format: {
            type: String
        }
    },
    data: () => ({
        menu: false,
        ICONS
    }),
    computed: {
        ...mapState('locale', [
            'currentLocale'
        ]),
        showClearButton () {
            return this.clearable && this.$attrs.value;
        },
        date () {
            return this.$attrs.value;
        },
        dateFormatted () {
            return this.$attrs.value?.map((date) => {
                return PARSE_DATE(date, this.format, this.currentLocale);
            }).join(', ') || '';
        }
    },
    methods: {
        clearDate () {
            this.$refs.menu.save(null);
            this.$emit('input', null);
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep {
    & .v-text-field {
        & .v-input__prepend-outer {
            margin-top: 9px;
        }
    }

    & .v-picker--disabled {
        .v-date-picker-header {
            display: none;
        }

        .v-date-picker-title__year {
            display: none;
        }
    }
}
</style>
