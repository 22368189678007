<template>
   <v-row class="mb-5"
          :justify="justify">
       <v-col :lg="!alwaysMinified && 3"
              :md="!alwaysMinified && 4"
              cols="12"
              :class="{ 'pb-0': alwaysMinified || $vuetify.breakpoint.smAndDown }">
           <Search v-if="withSearch"
                   @search="handleSearch($event)"></Search>
       </v-col>
       <v-col :lg="!alwaysMinified && 8"
              :offset-lg="!alwaysMinified ? 1 : 0"
              :md="!alwaysMinified && 7"
              :offset-md="!alwaysMinified ? 1 : 0"
              cols="12"
              :class="{ 'pt-0': alwaysMinified || $vuetify.breakpoint.smAndDown }">
           <Filters ref="filter"
                    :alwaysMinified="alwaysMinified"
                    :minWidth="minWidth"
                    v-if="withFilters"
                    :is-modal="isModal"
                    @reset="emitReset"></Filters>
       </v-col>
   </v-row>
</template>

<script>
import Search from '@/components/widgets/tables/Search';
import Filters from '@/components/widgets/tables/Filters';

export default {
    name: 'FiltersBar',
    components: { Search, Filters },
    props: {
        withSearch: {
            type: Boolean,
            'default': true
        },
        withFilters: {
            type: Boolean,
            'default': true
        },
        isModal: {
            type: Boolean,
            'default': false
        },
        alwaysMinified: {
            type: Boolean,
            'default': false
        },
        minWidth: {
            type: Number,
            'default': 1
        }
    },
    computed: {
        justify () {
            return (this.withSearch && this.withFilters) ? 'space-between' : 'end';
        }
    },
    methods: {
        handleSearch (event) {
            this.$emit('search', event);
        },
        resetSpecifyFilter (prop) {
            this.$refs.filter.resetSpecifyFilter(prop);
        },
        setSpecifyFilter (prop, val) {
            this.$refs.filter.setSpecifyFilter(prop, val);
        },
        clearFilters () {
            this.$refs.filter.clearFilters();
        },
        emitReset () {
            this.$emit('reset');
        }
    }
};
</script>

<style scoped>

</style>
