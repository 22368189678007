import FilterInstance from '@/models/filters/filterInstance';

class YearPickerFilter extends FilterInstance {
    constructor (params) {
        super({
            ...params,
            type: 'YearPicker'
        });

        this.max = params.max;

        if (Object.hasOwnProperty.call(params, 'referralCallback')) {
            this.referralCallback = params.referralCallback;
        }
    }
}

export default YearPickerFilter;
