export default {
    computed: {
        isRequired () {
            if (this.rules) {
                if (typeof this.rules === 'string') {
                    return this.rules.indexOf('required') !== -1;
                }
                if (Array.isArray(this.rules)) {
                    return this.rules.find('required');
                }
                if (this.isObject(this.rules)) {
                    return Object.hasOwnProperty.call(this.rules, 'required');
                }
            }
            return false;
        }
    },
    methods: {
        isObject (item) {
            return (typeof item === 'object' && !Array.isArray(item) && item !== null);
        }
    }
};
