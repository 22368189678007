<template>
    <ValidationProvider :name="$attrs.label"
                        :vid="name"
                        :rules="rules"
                        v-slot="{ errors }">
        <v-text-field v-on="$listeners"
                      :name="name"
                      @input="$emit('input', $event)"
                      @blur="$emit('blur', $event)"
                      :class="className"
                      :error-messages="errors"
                      :hint="hint"
                      outlined
                    v-bind="$attrs">
            <template slot="label">
                {{ $attrs.label }}
                <span v-if="isRequired && !hideRequiredMark" class="error--text">*</span>
            </template>
            <template :slot="slotName"
                      v-if="slotName">
                <slot name="slotContent"></slot>
            </template>
        </v-text-field>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';

export default {
    name: 'TextField',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        slotName: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        hideRequiredMark: {
            type: Boolean,
            'default': false
        },
        hint: {
            type: String,
            'default': ''
        },
        className: {
            type: [String, Array, Object],
            required: false
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    ::v-deep {
        & .v-text-field__details {
            & .v-messages {
                &.success--text {
                    color: $text_lighter !important;
                    caret-color: $text_lighter !important;
                    & .v-messages__message {
                        font-size: $xxs;
                        color: $text_lighter;
                    }
                }
            }
        }
        & .v-input {
            &.success--text {
                & .v-label {
                    &.success--text {
                        color: $text_lighter !important;
                    }
                }
            }
            &.v-text-field--outlined {
                &.success--text {
                    & fieldset {
                        border-width: 1px;
                        border-color: $text_lighter_2;
                    }
                }
            }
            &.v-input--is-focused {
                &.success--text {
                    & fieldset {
                        border-width: 2px;
                        border-color: $primary;
                    }
                }
            }
        }
    }
</style>
