import FilterInstance from '@/models/filters/filterInstance';

class MonthPickerFilter extends FilterInstance {
    constructor (params) {
        super({
            ...params,
            type: 'MonthPicker'
        });
    }
}

export default MonthPickerFilter;
